.container-inner p {
    margin-bottom: 20px !important;
}
.wrapper{
    background-color: #008080; 
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    box-shadow: 0 0 5px rgba(0,0,0,0.6);
}

.bar{
    position: absolute;
    bottom: 0;
    height: 30px;
    background-color: #C0C0C0;
    width: 100%;
    border-top: 3px solid #FFFFFF;
    color: #000;
}

@media only screen and (max-width: 850px)
{
    .wrapper, .bar{
        width: 100%;
    }

    .container {
        width: 90% !important;
        left: 5% !important;
    }
}

.start-button{
    display: inline-block;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    height: 25px;
    margin-left: 2px;
    margin-top: 1px;
    font-weight: bold;
    padding-left: 2px;
    padding-right: 2px;
    width: 70px;
}

.start-button>span{
    position: absolute;
    bottom: 3px;
}

.time{
    position: relative;
    width: 90px;
    border-top: 2px solid #6D6E6B;
    border-left: 2px solid #6D6E6B;
    border-bottom: 2px solid #ffffff ;
    border-right: 2px solid #ffffff;
    height: 24px;
    float: right;
    margin-right: 3px;
    margin-top: 1px;
}

.time>span{
    font-size: 0.8em;
    position: absolute;
    bottom: 1px;
    margin-left: 10px;
}

.desktop{
    color: white;
    font-size: 0.8em;
}

.icons{
    list-style-type: none;
    padding: 0;
}

.icons>li{
    display: inline-block;
    width: 50px;
    margin: 10px;
    text-align:center;
}

.icons>li>img{
    display: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px;
    width: 37px;
    height: 35px;
}

.container {
    position: absolute;
    top: 60px;
    left: calc( 50% - 450px );
    min-width: 300px;
    width: 960px;
    color: black;
    border: 2px solid;
    border-bottom-color: #535353;
    border-right-color: #535353;
    border-left-color: #dbdbdb;
    border-top-color: #dbdbdb;
    background-color: #bfbfbf;
    font-size: 13px;
    padding: 2px;
}

.container ul.toolbar {
    list-style: none;
    padding: 2px 0;
    margin: 0;
}
.container ul.toolbar li {
    display: inline-block;
    padding: 0 5px;
}
.container .container-inner {
    border: 2px solid;
    border-bottom-color: #c7c7c7;
    border-right-color: #c7c7c7;
    border-left-color: #808080;
    border-top-color: #808080;
    background-color: #fff;
    padding: 10px;
    height:calc( 80vh - 160px );
    overflow: auto;
}

.container .container-inner a {
    color: cyan;
    background: black;
    padding: 3px 6px;
}

.container .container-inner .flash {
    color: red;
    background: yellow;
    padding: 8px 12px;
    margin: 30px 0;
    line-height: 35px;
    font-weight: bold;
}

.container .container-inner ul.icons {
    list-style: none;
    padding: 2px 0;
    margin: 0;
}
.container .container-inner ul.icons li {
    display: inline-block;
    padding: 0 5px;
}
.container .statusbar .left, .container .statusbar .right {
    border: 1px solid;
    border-color: #888 #dadada #dadada #888;
    display: inline-block;
    padding: 0px 5px;
    font-size: 13px;
}
.container .statusbar .left {
    width: 33%;
}
.container .statusbar .right {
    width: 66%;
}
.title {
    cursor: grab;
    background-color: #808080;
    padding: 2px 4px 3px 4px;
    position: relative;
}
.title h1 {
    font-weight: bold;
    color: #bcbcbc;
    font-size: 13px;
    margin: 0;
}
.title button {
    border: 2px solid;
    border-bottom-color: #444;
    border-right-color: #444;
    border-left-color: #a6a6a6;
    border-top-color: #a6a6a6;
    background-color: #bfbfbf;
    position: absolute;
    font-size: 9px;
    line-height: 0;
    padding: 1px;
    height: 15px;
    width: 15px;
    top: 2px;
}
.title button.minimize {
    right: 34px;
}
.title button.expand {
    right: 18px;
}
.title button.times {
    right: 2px;
}

table {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    width: 100%;
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
table th, table td {
    padding: 10px 15px;
}