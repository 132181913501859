* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  background-color: black;
  color: #fff;
  font: 600 1rem/1.3 Consolas, Andale Mono, monospace;
}

.monitor {
  padding: 80px;
  background: rgb(237, 240, 237);
  background: linear-gradient(
    167deg,
    rgba(237, 240, 237, 1) 0%,
    rgba(186, 193, 159, 1) 100%
  );
  height: 100vh;
}

@media only screen and (max-width: 850px)
{
    .monitor {
      padding: 80px 0;
    }
}

.screen {
  position: relative;
  overflow: hidden;
  background-color: #000;
  border: 5px solid #767879;
  border-right-color: #ffffff;
  border-bottom-color: #d3d3d3;
  border-left-color: #868c72;
  box-shadow: inset 0 0 3px #000;
  height: 100%;
}

.content {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #00f;
  padding: 30px;
}

.monitor:before {
  display: block;
  background-image: url(./img/panasync-logoremake.png);
  width: 240px;
  height: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 30px;
  content: ' ';
}

.line {
  display: block;
  font-size: 12px;
}

a {
  color: white;
  text-decoration: underline;
}
